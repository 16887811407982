import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{staticStyle:{"background-color":"white"}},[_c(VAppBar,{attrs:{"app":"","color":"white","fixed":"","flat":""}},[_c(VSpacer),_c(VImg,{attrs:{"src":"/media/logos/logo-itograss-2.png","max-width":"180"}}),_c(VSpacer)],1),_c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_c(VTextField,{staticClass:"mb-4",attrs:{"append-icon":"mdi-magnify","clearable":"","dense":"","placeholder":"Pesquisar...","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('pages-compras-aprovador-lista',{attrs:{"items":_vm.filteredItems,"height":_vm.virtualScrollHeight,"loading":_vm.loading},on:{"removeItem":function($event){return _vm.removeItem($event)}}})],1),_c('custom-snackbar',{ref:"snackbar"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }