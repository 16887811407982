<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="text--primary"
          small
          depressed
          v-bind="attrs"
          v-on="on"
      >
        detalhes
        <v-icon right small>mdi-open-in-new</v-icon>
      </v-btn>
    </template>
    <v-card>
    <v-app>
      <v-app-bar
          color="white"
          fixed
          flat
      >
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
            class="mr-4"
            icon
            small
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <v-card flat>
            <v-card-title>
              Detalhes
            </v-card-title>
            <v-card-text>
              <v-card outlined>
                <v-card-text class="pb-0">
                  <v-row>
                    <v-col cols="12" class="subtitle-2">
                      Nº:
                    </v-col>
                    <v-col cols="12">
                      {{ item.numero | formatNumber(0)}}
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  <v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-col cols="12" class="subtitle-2">
                          Solicitante:
                        </v-col>
                        <v-col cols="12">
                          {{ item.solicitante }}
                        </v-col>
                      </v-col>
                      <v-col cols="6">
                        <v-col cols="12" class="subtitle-2">
                          Data:
                        </v-col>
                        <v-col cols="12">
                          {{ item.data }}
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-card-text>
                <v-divider class="my-0"/>
                <v-card-text class="pb-0">
                  <v-row>
                    <v-col cols="12" class="subtitle-2">
                      Empresa:
                    </v-col>
                    <v-col cols="12">
                      {{ item.empresa_descricao }}
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="subtitle-2">
                      Produção:
                    </v-col>
                    <v-col cols="12">
                      {{ item.filial_descricao }}
                    </v-col>
                  </v-row>
                </v-card-text>
                <template v-if="item.observacao">
                  <v-divider class="my-0"/>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="subtitle-2">
                        Observação:
                      </v-col>
                      <v-col cols="12">
                        {{ item.observacao }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </template>
              </v-card>
            </v-card-text>

            <v-card-title>
              Itens
            </v-card-title>
            <v-card-text>
              <v-card outlined>
                <template v-for="(produto, i) in item.items">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="subtitle-2">
                        Descrição
                      </v-col>
                      <v-col cols="12">
                        {{ produto.descricao }}
                      </v-col>
                      <v-col cols="4">
                        <v-col cols="12" class="subtitle-2">
                          Quantidade
                        </v-col>
                        <v-col cols="12">
                          {{ produto.quantidade_solicitada | formatNumber }}
                        </v-col>
                      </v-col>
                      <v-col cols="4">
                        <v-col cols="12" class="subtitle-2">
                          Valor Unit.
                        </v-col>
                        <v-col cols="12">
                          R$ {{ produto.valor_unitario | formatNumber }}
                        </v-col>
                      </v-col>
                      <v-col cols="4">
                        <v-col cols="12" class="subtitle-2">
                          Valor Total
                        </v-col>
                        <v-col cols="12">
                          R$ {{ produto.valor_total | formatNumber }}
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider v-if="item.items.length > (i+1)" class="my-0"/>
                </template>
              </v-card>
            </v-card-text>
            <v-card-actions class="mt-4">
              <v-spacer />
              <pages-compras-aprovador-confirm-dialog
                  :processing="processing"
                  @confirm="reprovar(item)"
              >
                <template #action="{ on, attrs }">
                  <v-btn
                      color="red"
                      dark
                      depressed
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon left>mdi-close</v-icon>
                    Reprovar
                  </v-btn>
                </template>
              </pages-compras-aprovador-confirm-dialog>
              <v-spacer />
              <pages-compras-aprovador-confirm-dialog
                  :processing="processing"
                  @confirm="aprovar(item)"
              >
                <template #action="{ on, attrs }">
                  <v-btn
                      color="green"
                      dark
                      depressed
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon left>mdi-check</v-icon>
                    Aprovar
                  </v-btn>
                </template>
              </pages-compras-aprovador-confirm-dialog>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
    </v-card>
  </v-dialog>
</template>

<script>
import apiService from '@/core/services/api.service'
import PagesComprasAprovadorConfirmDialog from "@/view/pages/aprovador/components/ConfirmDialog.vue";
export default {
  name: 'PagesComprasAprovadorItemDetail',
  components: {PagesComprasAprovadorConfirmDialog},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    processing: false,
  }),

  computed: {
    title () {
      switch (this.item.sigla) {
        case 'OC':
          return 'Ordem de Compra'
        case 'OS':
          return 'Ordem de Serviço'
        case 'CD':
          return 'Compra Direta'
        case 'SC':
          return 'Solicitação de Compra'
        default:
          return 'Tipo de Documento Indefinido'
      }
    },
    snackbar () {
      return this.$parent.$parent.$parent.$parent.$parent.$parent.$refs.snackbar
    }
  },

  methods: {
    async aprovar (item) {
      this.processing = true

      try {
        if (item.sigla === 'SC') {
          const items = []

          item.items.forEach(function (value) {
            items.push({
              'empresa': item.empresa,
              'numero': item.numero,
              'sequencia': value.sequencia,
            })
          })

          await apiService._post('compras/aprovador/aprovar_sc', { items: items }, {
            snackbar: this.snackbar
          })
        } else {
          await apiService._post('compras/aprovador/aprovar_oc', {
            'empresa': item.empresa,
            'filial': item.filial,
            'numero': item.numero,
          }, {
            snackbar: this.snackbar
          })
        }

        this.$emit('removeItem', item)

        this.dialog = false
      } catch (e) {
        console.error(e)
      }

      this.processing = false
    },

    async reprovar (item) {
      this.processing = true

      try {
        if (item.sigla === 'SC') {
          const items = []

          item.items.forEach(function (value) {
            items.push({
              'empresa': item.empresa,
              'numero': item.numero,
              'sequencia': value.sequencia,
            })
          })

          await apiService._post('compras/aprovador/reprovar_sc', { items: items }, {
            snackbar: this.snackbar
          })
        } else {
          await apiService._post('compras/aprovador/reprovar_oc', {
            'empresa': item.empresa,
            'filial': item.filial,
            'numero': item.numero,
          }, {
            snackbar: this.snackbar
          })
        }

        this.$emit('removeItem', item)

        this.dialog = false
      } catch (e) {
        console.error(e)
      }

      this.processing = false
    },
  }
}
</script>