<template>
  <v-dialog v-model="dialog" persistent>
    <template v-slot:activator="{ on, attrs }">
      <slot
          name="action"
          :attrs="attrs"
          :on="on"
      ></slot>
    </template>
    <v-card :disabled="processing" :loading="processing">
      <v-card-text class="title pt-3">
        Esta ação não poderá ser desfeita. Deseja continuar?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
            color="primary"
            text
            @click="dialog = !dialog"
        >
          Não
        </v-btn>
        <v-btn
            color="primary"
            depressed
            @click="$emit('confirm')"
        >
          Sim
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PagesComprasAprovadorConfirmDialog',

  props: {
    processing: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
  }),

  watch: {
    processing: function (newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.dialog = false
      }
    },
  }
}
</script>