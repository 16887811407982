import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VVirtualScroll,{attrs:{"bench":"2","height":_vm.height,"item-height":137.6,"items":!_vm.loading ? _vm.items : [1,2,3,4,5,6]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(_vm.loading)?_c(VSkeletonLoader,{key:item + '_skeleton',attrs:{"height":127.6,"type":"image"}}):_c(VCard,{key:item.numero+item.descricao,attrs:{"outlined":""}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c(VAvatar,{staticClass:"mt-4 mb-4 ml-2",attrs:{"color":item.cor,"size":"48"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(item.sigla))])])],1),_c(VCol,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"py-0 mt-2 px-2"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_vm._v("Nº"),_c('span',{staticClass:"black--text ml-1"},[_vm._v(_vm._s(item.numero))])]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[_vm._v(_vm._s(item.data))])],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_vm._v(" Filial: "),_c('span',{staticClass:"black--text ml-1"},[_vm._v(_vm._s(item.filial_descricao)+" ")])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(item.sigla !== 'SC' ? 'Fornecedor:' : 'Item:')+" ")]),_c(VCol,[_c('div',{staticClass:"black--text pl-1 text-truncate",staticStyle:{"max-width":"210px"}},[_vm._v(" "+_vm._s(item.descricao)+" ")])])],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"auto"}},[_vm._v(" Solicitante: ")]),_c(VCol,[_c('div',{staticClass:"black--text pl-1 text-capitalize text-truncate",staticStyle:{"max-width":"140px"}},[_vm._v(" "+_vm._s(item.solicitante.replace('.', ' '))+" ")])]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"auto"}},[_c('span',{staticClass:"black--text"},[_vm._v("R$ "+_vm._s(_vm._f("formatNumber")(item.valor_aproximado)))])])],1)],1)],1)],1)],1)],1)],1),_c(VCardActions,{staticClass:"px-2"},[_c(VSpacer),_c('pages-compras-aprovador-item-detail',{attrs:{"item":item},on:{"removeItem":function($event){return _vm.$emit('removeItem', $event)}}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }