<template>
  <v-app style="background-color: white;">
    <v-app-bar
      app
      color="white"
      fixed
      flat
    >
      <v-spacer />
      <v-img src="/media/logos/logo-itograss-2.png" max-width="180" />
      <v-spacer />
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="mb-4"
            clearable
            dense
            placeholder="Pesquisar..."
            outlined
            hide-details
        />
        <pages-compras-aprovador-lista
            :items="filteredItems"
            :height="virtualScrollHeight"
            :loading="loading"
            @removeItem="removeItem($event)"
        />
      </v-container>
      <custom-snackbar ref="snackbar" />
    </v-main>
  </v-app>
</template>

<script>
import PagesComprasAprovadorLista from "@/view/pages/aprovador/components/Lista.vue";
import apiService from "@/core/services/api.service";

export default {
  name: 'PagesComprasAprovadorIndex',

  components: {PagesComprasAprovadorLista},

  data: () => ({
    loading: true,
    items: [],
    search: null,
    virtualScrollHeight: 0,
  }),

  computed: {
    filteredItems() {
      if (!this.search) {
        return this.items;
      }

      return this.items.filter(item => {
        return Object.values(item).some(val =>
            String(val).toLowerCase().includes(this.search.toLowerCase())
        );
      });
    }
  },

  mounted() {
    this.virtualScrollHeight = this.$parent.$el.offsetHeight - 136

    this.init()
  },

  methods: {
    async init() {
      this.loading = true

      try {
        this.items = (await apiService._get('compras/aprovador', {
          snackbar: this.$refs.snackbar
        })).data
      } catch (e) {
        console.error(e)
      }

      this.loading = false
    },

    removeItem (item) {
      const itemIndex = this.items.indexOf(item)
      this.search = null
      this.items.splice(itemIndex, 1)
    }
  }
};
</script>