<template>
  <v-virtual-scroll
      bench="2"
      :height="height"
      :item-height="137.6"
      :items="!loading ? items : [1,2,3,4,5,6]"
  >
    <template v-slot:default="{ item }">
      <v-skeleton-loader
          v-if="loading"
          :key="item + '_skeleton'"
          :height="127.6"
          type="image"
      />
      <v-card
        v-else
        :key="item.numero+item.descricao"
        outlined
    >
      <v-row dense>
        <v-col
            class="d-flex align-center"
            cols="auto"
        >
          <v-avatar
              class="mt-4 mb-4 ml-2"
              :color="item.cor"
              size="48"
          >
            <span class="white--text text-h5">{{ item.sigla }}</span>
          </v-avatar>
        </v-col>
        <v-col>
          <v-card flat>
            <v-card-text class="py-0 mt-2 px-2">
              <v-row dense>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="6">Nº<span class="black--text ml-1">{{ item.numero }}</span></v-col>
                    <v-col cols="6" class="text-right">{{ item.data }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  Filial: <span class="black--text ml-1">{{ item.filial_descricao }}
                </span></v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="auto">
                      {{ item.sigla !== 'SC' ? 'Fornecedor:' : 'Item:'}}
                    </v-col>
                    <v-col>
                      <div class="black--text pl-1 text-truncate" style="max-width: 210px;">
                        {{ item.descricao }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row dense>
                    <v-col cols="auto">
                      Solicitante:
                    </v-col>
                    <v-col>
                      <div class="black--text pl-1 text-capitalize text-truncate" style="max-width: 140px;">
                        {{ item.solicitante.replace('.', ' ') }}
                      </div>
                    </v-col>
                    <v-col cols="auto" class="text-right">
                      <span class="black--text">R$ {{ item.valor_aproximado | formatNumber }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-card-actions class="px-2">
        <v-spacer />
        <pages-compras-aprovador-item-detail
          :item="item"
          @removeItem="$emit('removeItem', $event)"
        />
      </v-card-actions>
    </v-card>
    </template>
  </v-virtual-scroll>
</template>

<script>
import PagesComprasAprovadorItemDetail from "@/view/pages/aprovador/components/ItemDetail.vue";

export default {
  name: 'PagesComprasAprovadorLista',

  components: { PagesComprasAprovadorItemDetail },

  props: {
    height: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
}
</script>